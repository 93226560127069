import {Grid} from "@mui/material";
import React from "react";
import Members from "./Members";
import AddMemberDialog from "./AddMemberDialog";


export type Member = {
    name: string
    email: string
    isAdmin: boolean
}

export type OrganizationDetailsProps = {
    isCurrIsUserAdmin: boolean
    organizationName: string
    members: Member[]
    pendingMemberEmails: string[]
    onNewMemberAdded: () => void
}
const OrganizationDetails = (props: OrganizationDetailsProps) => {
    return <Grid container className={"mt-4"}>
        <Grid item xs={12}>
            <div className={"text-muted-foreground"}>
                {props.organizationName} Members:
            </div>
            <Members members={props.members} pendingMemberEmails={props.pendingMemberEmails}/>
        </Grid>
        {props.isCurrIsUserAdmin &&
            (<Grid item xs={12}>
                <AddMemberDialog refreshData={props.onNewMemberAdded}/>
            </Grid>)}
    </Grid>
}


export default OrganizationDetails;
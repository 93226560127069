import React, {useState} from "react";
import {Step, StepContent, StepLabel, Stepper} from "@mui/material";
import ObservabilityProviderCredentials from "components/settings/credentials/ObservabilityProviderCredentials";
import {Button} from "../ui/button";


export const OnboardingStepper: React.FC = () => {
    const [activeStep, setActiveStep] = useState(0);

    const handleNextStep = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

    const credentialsSetup =
        <div>
            <p className="text-sm text-muted-foreground mb-4">
                Metoro will use these credentials to fetch data from your observability provider when you start an
                investigation.
            </p>
            <ObservabilityProviderCredentials
                credentialData={{}}
                isCredentialsSet={false}
                isAdmin={true}
                observabilityVendor={""}
                isOnboarding={true}
                onSubmit={() => {
                    handleNextStep();
                }}
            />
        </div>


    const steps = [
        {
            label: 'Fill in your observability provider credentials',
            child: credentialsSetup,
        },
        {
            label: 'Run your first investigation',
            child:
                <div>
                    <p className="text-sm text-muted-foreground mb-4">
                        You're all set! Now you can run your first investigation.
                    </p>
                    <Button className="btn btn-primary" onClick={() => {
                        window.location.href = "/search";
                    }}>Start an investigation
                    </Button>
                </div>
        }
    ];

    return (
        <Stepper sx={{
            '& .MuiStepIcon-root': {color: 'black'},
            '& .MuiStepIcon-root.Mui-active': {color: 'black'},
            '& .MuiStepIcon-root.Mui-completed': {color: 'black'},
        }} activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
                <Step key={step.label}>
                    <StepLabel sx={{
                        '& .MuiStepLabel-label': {fontSize: '16px', fontWeight: 'bold'}
                    }}>{step.label}</StepLabel>
                    <StepContent>
                        {step.child}
                    </StepContent>
                </Step>
            ))}
        </Stepper>
    )
}
import React, {useEffect, useState} from "react";
import {fetchAccountOrganizationDetails} from "../settings/settings";
import {OnboardingStepper} from "../../components/onboardingflow/OnboardingStepper";
import {Button} from "../../components/ui/button";
import useAuth from "../../hooks/useAuth";


export const Onboarding = ({children}: any) => {
    const [shouldOnboard, setShouldOnboard] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const jwtContext = useAuth();


    useEffect(() => {
        fetchAccountOrganizationDetails().then((response) => {
            setIsLoading(false);
            const details = response.data
            if (!details.credentialsSet) {
                setShouldOnboard(true);
            }
        }).catch((e) => {
            setIsLoading(false);
            console.error(e);
        })
    }, [jwtContext])

    if (isLoading) {
        return <>Loading...</>
    }


    if (shouldOnboard) {
        // If credentials are not set, show onboarding guide
        // return <Card className={"m-4"}>
        //     <CardHeader>
        //         <CardTitle>
        //             Onboarding Guide
        //         </CardTitle>
        //         <CardDescription>
        //             Let's get you set up for your first investigation.
        //         </CardDescription>
        //     </CardHeader>
        //     <Separator/>
        //     <CardContent>
        //         <OnboardingStepper/>
        //     </CardContent>
        // </Card>
        return (
            <div className={"p-4"}>
                <div className={"mb-4"}>
                    <div className={"flex justify-between"}>
                        <div>
                            <h2 className="text-2xl font-bold tracking-tight">Onboarding</h2>
                            <p className="text-sm text-muted-foreground">
                                Hang tight! We just need to set up a few things before you can start using Metoro.
                            </p>
                        </div>
                        <div>
                            <Button onClick={() => {
                                jwtContext.logout()
                                window.location.href = "/login"
                            }}>Log out</Button>
                        </div>
                    </div>
                </div>

                <OnboardingStepper/>
            </div>)
    }

    return (
        <>{children}</>
    );
}

import {LogOutIcon} from "lucide-react";
import {JWTContextType} from "../types/auth";

const uselogout = (jwtContextType: JWTContextType) => {
    return {
        id: 'group-logout',
        title: "Log out",
        url: '#',
        icon: LogOutIcon,
        onClick: () => {
            jwtContextType.logout();
        },
        type: 'group',
    }
};

export default uselogout;
// assets
// type
import {NavItemType} from 'types/menu';
import {SirenIcon} from "lucide-react";

// ==============================|| MENU ITEMS - MANAGE ||============================== //

const alert: NavItemType = {
    id: 'group-alerts',
    title: "Alerts",
    icon: SirenIcon,
    type: 'group',
    url: '/alerts',
    children: [
        {
            id: 'alerts',
            title: 'Alerts',
            type: 'item',
            url: '/alerts',
            icon: SirenIcon,
        },
    ]
};

export default alert;

import {NavItemType} from "../types/menu";
import {ReceiptIcon} from "lucide-react";

const billing: NavItemType = {
    id: 'group-billing',
    title: "Billing",
    icon: ReceiptIcon,
    type: 'group',
    url: '/billing',
    children: [
        {
            id: 'billing',
            title: 'Billing',
            type: 'item',
            url: '/billing',
            icon: ReceiptIcon,
        },
    ]
};

export default billing;

// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';

import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';
import {IntercomProvider} from 'react-use-intercom';


// auth-provider
import {JWTProvider as AuthProvider} from 'contexts/JWTContext';
import {Onboarding} from "./pages/onboardingflow/onboarding";

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
    const INTERCOM_APP_ID = 'nfldkjzk';
    return (
        <IntercomProvider appId={INTERCOM_APP_ID}>
            <ThemeCustomization>
                <RTLLayout>
                    <Locales>
                        <ScrollTop>
                            <AuthProvider>
                                <Notistack>
                                    <Onboarding>
                                        <Routes/>
                                        <Snackbar/>
                                    </Onboarding>
                                </Notistack>
                            </AuthProvider>
                        </ScrollTop>
                    </Locales>
                </RTLLayout>
            </ThemeCustomization>
        </IntercomProvider>
    );
};

export default App;

// assets
// type
import {NavItemType} from 'types/menu';
import {HistoryIcon, SearchIcon} from "lucide-react";

// ==============================|| MENU ITEMS - MANAGE ||============================== //

const investigate: NavItemType = {
    id: 'group-alerts',
    title: "Investigate",
    icon: SearchIcon,
    type: 'group',
    url: '/search',
    children: [
        {
            id: 'search',
            title: 'Run',
            type: 'item',
            url: '/search',
            icon: SearchIcon
        },
        {
            id: 'my-searches',
            title: 'History',
            type: 'item',
            url: '/my-searches',
            icon: HistoryIcon,
        }
    ]
};

export default investigate;

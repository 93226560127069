// project import
// types
import investigate from "./investigate";
import alert from "./alert";
import feedback from "./feedback";
import changelog from "./changelog";
import {NavItemType} from "../types/menu";
import {IntercomContextValues} from "react-use-intercom";
import settings from "./settings";
import billing from "./billing";
import uselogout from "./logout";
import {JWTContextType} from "../types/auth";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = (jwtContext: JWTContextType, intercom: IntercomContextValues) : {items: NavItemType[]} =>  {
    return {
        items: [alert, investigate, settings, billing, changelog, feedback(intercom), uselogout(jwtContext)]
    }
};

export default menuItems;

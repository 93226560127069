import {Divider, Grid, Typography} from "@mui/material";
import React from "react";
import {Card} from "../../components/ui/card";
import ObservabilityProviderCredentials, {
    ObservabilityProviderCredentialsProps
} from "../../components/settings/credentials/ObservabilityProviderCredentials";

export const Integrations = (props: ObservabilityProviderCredentialsProps) => {
    return (
    <Card className={"p-4"}>
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h5"><b>Integrations</b></Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={"mt-4"}/>
            </Grid>
            <Grid item xs={12}>
                <ObservabilityProviderCredentials
                    {...props}
                />
            </Grid>
        </Grid>
    </Card>)
}

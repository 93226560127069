import React from "react";
import axios from "../../../utils/axios";
import {SignalFxCredential} from "./ObservabilityProviderCredentials";
import {z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from "../../ui/form";
import {useToast} from "../../ui/use-toast";
import {Input} from "../../ui/input";
import {Button} from "../../ui/button";

const setupCredentials = async (apiUrl: string, apiKey: string) => {
    const credential = {
        apiUrl: apiUrl,
        apiKey: apiKey
    }

    return await axios.post(
        '/api/v1/account/organization/integrations',
        {
            type: 'observability',
            name: 'signalfx',
            credential: JSON.stringify(credential)
        }
    );
}

type SignalFxCredentialsInputProps = {
    isEditable: boolean
    onSubmit: () => void;
    credentials: SignalFxCredential | undefined
}

const signalFxFormSchema = z.object({
    apiUrl: z
        .string()
        .url({message: "Please enter a valid URL. E.g. https://api.signalfx.com"})
        .min(2, {
            message: "url must be at least 2 characters.",
        }),
    apiKey: z
        .string({
            required_error: "'SignalFx API Key is required",
        }).min(1, {
            message: "API Key must be at least 1 characters.",
        })
    ,
})

type SignalFxFormValues = z.infer<typeof signalFxFormSchema>


const SignalFxCredentials = (props: SignalFxCredentialsInputProps) => {
    const currCredentials = props.credentials;
    const a = useToast()
    const myToast = a.toast


    // This can come from your database or API.
    const defaultValues: Partial<SignalFxFormValues> = {
        apiUrl: currCredentials?.apiUrl,
        apiKey: currCredentials?.apiKey,
    }

    const form = useForm<SignalFxFormValues>({
        resolver: zodResolver(signalFxFormSchema),
        defaultValues,
        mode: "onChange",
    })

    const onSubmit = async (data: SignalFxFormValues) => {
        setupCredentials(data.apiUrl, data.apiKey)
            .then(response => {
                props.onSubmit();
            }).then(() => {
            myToast({
                title: "Credentials added",
                description: "You have successfully added SignalFx credentials",
            })
        })

    }


    return <Form {...form}>
        <form onSubmit={async (event) => {
            event.preventDefault();
            const data = Object.fromEntries(new FormData(event.currentTarget));
            try {
                const res = signalFxFormSchema.parse(data);
                await form.handleSubmit(onSubmit)()
            } catch (error) {
                console.log(error);
            }
        }}>
            <FormField
                control={form.control}
                name="apiUrl"
                render={({field}) => (
                    <FormItem>
                        <FormLabel>Api Url</FormLabel>
                        <FormControl>
                            <Input disabled={!props.isEditable} placeholder="https://api.signalfx.com" {...field} />
                        </FormControl>
                        <FormDescription>
                            This is the URL of your SignalFx API instance, Metoro will attempt to connect to this URL to
                            fetch observability data.
                        </FormDescription>
                        <FormMessage/>
                    </FormItem>
                )}
            />
            <FormField
                control={form.control}
                name="apiKey"
                render={({field}) => (
                    <FormItem className={"mt-4"}>
                        <FormLabel>Api Key</FormLabel>
                        <FormControl>
                            <Input disabled={!props.isEditable} placeholder="YOUR_SIGNAL_API_KEY" {...field} />
                        </FormControl>
                        <FormDescription>
                            This is an API Key for your SignalFx API instance, Metoro will use this key to authenticate
                            with SignalFx.
                        </FormDescription>
                        <FormMessage/>
                    </FormItem>
                )}
            />
            <Button disabled={!props.isEditable} className={"mt-4"} type="submit">Update credentials</Button>
        </form>
    </Form>
}

export default SignalFxCredentials;

// Path: types.d.ts
// material-ui
import {TypographyVariantsOptions} from '@mui/material/styles';

// types
import {FontFamily, ThemeMode} from 'types/config';

// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //

const Typography = (mode: ThemeMode, fontFamily: FontFamily): TypographyVariantsOptions => ({
    htmlFontSize: 16,
    fontFamily,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
        fontWeight: 600,
        fontSize: '3.0rem',
        lineHeight: 1.5
    },
    h2: {
        fontWeight: 600,
        fontSize: '2.6666666666rem',
        lineHeight: 1.5
    },
    h3: {
        fontWeight: 600,
        fontSize: '2.333333333rem',
        lineHeight: 1.5
    },
    h4: {
        fontWeight: 600,
        fontSize: '2.0rem',
        lineHeight: 1.5
    },
    h5: {
        fontWeight: 600,
        fontSize: '1.66666666rem',
        lineHeight: 1.5
    },
    h6: {
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: 1.5
    },
    caption: {
        fontWeight: 400,
        fontSize: '0.6666666666rem',
        lineHeight: 1.5
    },
    body1: {
        fontSize: '1.0rem',
        lineHeight: 1.5
    },
    body2: {
        fontSize: '0.66666666rem',
        lineHeight: 1.5
    },
    subtitle1: {
        fontSize: '1.0rem',
        fontWeight: 600,
        lineHeight: 1.5
    },
    subtitle2: {
        fontSize: '0.666666666rem',
        fontWeight: 500,
        lineHeight: 1.5
    },
    overline: {
        lineHeight: 1.5
    },
    button: {
        textTransform: 'capitalize'
    }
});

export default Typography;

import {FormControl, Grid} from "@mui/material";
import React, {useState} from "react";
import SignalFx from "../../../assets/images/observability-logo/SignalFx.svg";
import GraphanaCloud from "../../../assets/images/observability-logo/GrafanaCloud.png";
import GrafanaCredentials from "./GrafanaCredentials";
import SignalFxCredentials from "./SignalFxCredentials";
import {Button} from "../../ui/button";
import {cn} from "../../ui/lib/utils";

export type GraphanaCredential = {
    grafanaAPIUrl: string,
    grafanaAPIKey: string,
    prometheusAPIUrl: string,
    prometheusAPIKey: string,
    prometheusUsername: string,
    tempoAPIUrl: string,
    tempoAPIKey: string,
    tempoUsername: string
}

export type SignalFxCredential = {
    apiUrl: string;
    apiKey: string;
}

export type ObservabilityProviderCredentialsProps = {
    isCredentialsSet: boolean;
    observabilityVendor: string;
    credentialData: any;
    isAdmin: boolean;
    onSubmit: () => void;
    isOnboarding?: boolean;
}

function renderSignalFx(sfxCredential: SignalFxCredential, isEditable: boolean, onSubmit: () => void) {
    return <Grid container xs={12} className={"mt-4"}>
        <Grid item xs={12}>
            <SignalFxCredentials isEditable={isEditable} onSubmit={onSubmit} credentials={sfxCredential}/>
        </Grid>
    </Grid>
}

function renderGrafana(grafanaCredential: GraphanaCredential, isEditable: boolean, onSubmit: () => void) {
    return <Grid item xs={12}>
        <GrafanaCredentials isEditable={isEditable} onSubmit={onSubmit} credentials={grafanaCredential}/>
    </Grid>
}

const emptySfxCredential: SignalFxCredential = {apiUrl: "", apiKey: ""};
const emptyGrafanaCredential: GraphanaCredential = {
    grafanaAPIUrl: "",
    grafanaAPIKey: "",
    prometheusAPIUrl: "",
    prometheusAPIKey: "",
    prometheusUsername: "",
    tempoAPIUrl: "",
    tempoAPIKey: "",
    tempoUsername: ""
};

const ObservabilityProviderCredentials = (props: ObservabilityProviderCredentialsProps) => {
    const [isGrafanaSelected, setIsGrafanaSelected] = useState(props.observabilityVendor === "grafana");
    const [isSignalFxSelected, setIsSignalFxSelected] = useState(props.observabilityVendor === "signalfx");
    const [isEditing, setIsEditing] = useState(!props.isCredentialsSet);
    const [shouldClearData, setShouldClearData] = useState(false);

    function handleSignalFxSelection() {
        setIsGrafanaSelected(false);
        setIsSignalFxSelected(true);
    }

    function handleGrafanaSelection() {
        setIsSignalFxSelected(false);
        setIsGrafanaSelected(true);
    }

    function getSignalFxButton(isEditable: boolean) {
        return (
            <Button disabled={!isEditable} onClick={handleSignalFxSelection}
                    className={cn(isSignalFxSelected ? "bg-gray-200" : " bg-transparent", "w-full h-[100px] hover:bg-gray-200")}>
                <div className={"flex justify-center"}>
                    <img src={SignalFx} width={180} height={100} alt='SignalFx'/>
                </div>
            </Button>
        )
    }

    function getGrafanaButton(isEditable: boolean) {
        return (
            <Button disabled={!isEditable} onClick={handleGrafanaSelection}
                    className={cn(isGrafanaSelected ? "bg-gray-200" : "bg-transparent", "w-full h-[100px]  hover:bg-gray-200")}>
                <img src={GraphanaCloud} width={180} height={100} alt='GrafanaCloud'/>
            </Button>
        )
    }

    const handleEdit = () => {
        setIsEditing(!isEditing)
        setShouldClearData(true)
    }

    const handleUndoEdit = () => {
        setIsEditing(!isEditing)
        setShouldClearData(false)
    }

    const handleSubmit = () => {
        setIsEditing(false)
        setShouldClearData(false)
        props.onSubmit()
    }

    return <Grid container xs={12}>
        {props.isCredentialsSet ?
            <Grid container className={"mt-4"}>
                <Grid item xs={10}>
                    <div className={"text-muted-foreground"}>
                        Your observability backend credentials are setup and you are ready to go!
                    </div>
                </Grid>
                {props.isAdmin
                    && (<Grid item xs={2} className={"flex justify-end"}>
                        {!isEditing && (
                            <Button onClick={handleEdit}>
                                <div className={"flex justify-between w-full"}>
                                    <div>Edit</div>
                                </div>
                            </Button>)}
                        {isEditing && (
                            <Button onClick={handleUndoEdit}>
                                <div className={"flex justify-between w-full"}>
                                    <div>Undo</div>
                                </div>
                            </Button>)}
                    </Grid>)}
            </Grid>
            :
            (!props.isOnboarding && <Grid item xs={12}>
                <div className={"mt-4 text-muted-foreground"}>
                    You have not setup an observability backend yet, please select one of the
                    options:
                </div>
            </Grid>)
        }
        <Grid container>
            <Grid item xs={12}>
                <FormControl className={"w-full"} component="fieldset">
                    <div className={"flex justify-space-between gap-4"}>
                        <div className={"w-full"}>
                            {getSignalFxButton(isEditing)}
                        </div>
                        <div className={"w-full"}>
                            {getGrafanaButton(isEditing)}
                        </div>
                    </div>
                </FormControl>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid container xs={12}>
                <Grid item xs={12}>
                    {(isSignalFxSelected) && shouldClearData &&
                        renderSignalFx(emptySfxCredential, isEditing, handleSubmit)}
                    {(isSignalFxSelected) && !shouldClearData &&
                        renderSignalFx(props.credentialData, isEditing, handleSubmit)}
                    {(isGrafanaSelected) && shouldClearData &&
                        renderGrafana(emptyGrafanaCredential, isEditing, handleSubmit)}
                    {(isGrafanaSelected) && !shouldClearData &&
                        renderGrafana(props.credentialData, isEditing, handleSubmit)}
                </Grid>
            </Grid>
        </Grid>
    </Grid>

};
export default ObservabilityProviderCredentials;

// Path: ObservabilityProviderCredentials.tsx
import React, {useEffect, useState} from 'react';

// material-ui
// project import
import {cn} from "../../../components/ui/lib/utils";
import {Separator} from "../../../components/ui/separator";
import {Nav, NavLink} from "../../../components/ui/nav";
import Logo from "assets/images/logo.png";
import {useIntercom} from "react-use-intercom";
import menuItem from "../../../menu-items";
import {NavItemType} from "../../../types/menu";
import {Link, useLocation} from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

interface Props {
    window?: () => Window;
    isCollapsed: boolean;
}

function navToLink(item: NavItemType, currentLocation: string): NavLink {
    return {
        title: item.title,
        icon: item.icon,
        variant: currentLocation.includes(item.url) ? "default" : "ghost",
        location: item.url,
        onClick: item.onClick
    };
}

const MainDrawer = ({...props}: Props) => {
    const intercom = useIntercom();
    const jwtContext = useAuth();
    const [menuItems, setMenuItems] = useState<NavLink[][]>([]);
    const location = useLocation();

    useEffect(() => {
        let items = menuItem(jwtContext, intercom);
        const links: NavLink[][] = [];
        links.push([]);
        for (let i = 0; i < items.items.length; i++) {
            // @ts-ignore
            if (items.items[i].children !== undefined && (items.items[i].children.length > 0)) {
                // @ts-ignore
                for (let j = 0; j < items.items[i].children.length; j++) {
                    // @ts-ignore
                    links[links.length - 1].push(navToLink(items.items[i].children[j], location.pathname));
                }
            } else {
                links[links.length - 1].push(navToLink(items.items[i], location.pathname));
            }
            if (links[links.length - 1][links[links.length - 1].length - 1].title === "History") {
                links.push([]);
            }

        }
        setMenuItems(links)
    }, [intercom, location, jwtContext]);


    return (
        <div className={"w-full h-screen flex flex-col"}>
            <Link to={"/"}>
                <div
                    className={cn("flex h-[64px] items-center justify-center", props.isCollapsed ? 'h-[64px]' : 'px-2')}>
                    <img src={Logo} alt="logo" className="h-12 w-auto"/>
                </div>
            </Link>
            <Separator/>
            {
                menuItems.map((links, index) => (
                    <>
                        <Nav key={index} links={links} isCollapsed={props.isCollapsed}/>
                        {index < menuItems.length - 1 &&
                            <Separator/>
                        }
                    </>
                ))

            }
        </div>
    );
};

export default MainDrawer;

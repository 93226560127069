// assets
// type
import {NavItemType} from 'types/menu';
import {FileDiffIcon} from "lucide-react";

// ==============================|| MENU ITEMS - MANAGE ||============================== //

const investigate: NavItemType = {
    id: 'group-changelog',
    title: "Changelog",
    icon: FileDiffIcon,
    type: 'group',
    url: '/changelog',
    children: [
        {
            id: 'changelog',
            title: 'Changelog',
            type: 'item',
            url: '/changelog',
            icon: FileDiffIcon
        },
    ]
};

export default investigate;

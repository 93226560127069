import React from "react";

import axios from "../../../utils/axios";
import {GraphanaCredential} from "./ObservabilityProviderCredentials";
import {z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {toast} from "../../ui/use-toast";
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from "../../ui/form";
import {Input} from "../../ui/input";
import {Button} from "../../ui/button";


type GrafanaCredentialsInputProps = {
    isEditable: boolean,
    onSubmit: () => void,
    credentials: GraphanaCredential | undefined
}

const setupCredentials = async (grafanaAPIUrl: string, grafanaAPIKey: string,
                                prometheusAPIUrl: string, prometheusAPIKey: string, prometheusUsername: string,
                                tempoAPIUrl: string, tempoAPIKey: string, tempoUsername: string
) => {
    const credential: GraphanaCredential = {
        grafanaAPIUrl: grafanaAPIUrl,
        grafanaAPIKey: grafanaAPIKey,
        prometheusAPIUrl: prometheusAPIUrl,
        prometheusAPIKey: prometheusAPIKey,
        prometheusUsername: prometheusUsername,
        tempoAPIUrl: tempoAPIUrl,
        tempoAPIKey: tempoAPIKey,
        tempoUsername: tempoUsername,
    }

    return await axios.post(
        '/api/v1/account/organization/integrations',
        {
            type: 'observability',
            name: 'grafana',
            credential: JSON.stringify(credential)
        }
    );
}


const grafanaFormSchema = z.object({
    grafanaUrl: z
        .string()
        .url({message: "Please enter a valid URL. E.g. https://metoro.grafana.net"})
        .min(2, {
            message: "url must be at least 2 characters.",
        }),
    grafanaKey: z
        .string({
            required_error: "'Grafana API Key is required E.g.glsa_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        })
        .min(2, {
            message: "Key must be at least 2 characters.",
        }),
    prometheusUrl: z
        .string()
        .url({message: "Please enter a valid URL. E.g. https://prometheus-prod-13-prod-us-east-0.grafana.net/api/prom"})
        .min(2, {
            message: "url must be at least 2 characters.",
        }),
    prometheusKey: z
        .string({
            required_error: "'Prometheus API Key is required E.g. glc_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        })
        .min(2, {
            message: "Key must be at least 2 characters.",
        }),
    prometheusUsername: z
        .string({
            required_error: "'Prometheus Username is required E.g. 1412503",
        })
        .min(2, {
            message: "Username must be at least 2 characters.",
        }),
    tempoUrl: z
        .string()
        .url({message: "Please enter a valid URL. E.g. https://tempo-prod-04-prod-us-east-0.grafana.net/tempo"})
        .min(2, {
            message: "url must be at least 2 characters.",
        }),
    tempoKey: z
        .string({
            required_error: "'Tempo API Key is required E.g. glc_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
        })
        .min(2, {
            message: "Key must be at least 2 characters.",
        }),
    tempoUsername: z
        .string({
            required_error: "'Tempo Username is required E.g. 802824",
        })
        .min(2, {
            message: "Username must be at least 2 characters.",
        }),
})


type GrafanaFormValues = z.infer<typeof grafanaFormSchema>


const GrafanaCredentials = (props: GrafanaCredentialsInputProps) => {
    const currCredentials = props.credentials;

    // This can come from your database or API.
    const defaultValues: Partial<GrafanaFormValues> = {
        grafanaUrl: currCredentials?.grafanaAPIUrl,
        grafanaKey: currCredentials?.grafanaAPIKey,
        prometheusUrl: currCredentials?.prometheusAPIUrl,
        prometheusKey: currCredentials?.prometheusAPIKey,
        prometheusUsername: currCredentials?.prometheusUsername,
        tempoUrl: currCredentials?.tempoAPIUrl,
        tempoKey: currCredentials?.tempoAPIKey,
        tempoUsername: currCredentials?.tempoUsername,
    }

    const form = useForm<GrafanaFormValues>({
        resolver: zodResolver(grafanaFormSchema),
        defaultValues,
        mode: "onChange",
    })

    const onSubmit = async (data: GrafanaFormValues) => {
        setupCredentials(data.grafanaUrl, data.grafanaKey, data.prometheusUrl, data.prometheusKey, data.prometheusUsername, data.tempoUrl, data.tempoKey, data.tempoUsername)
            .then(response => {
                props.onSubmit();
            }).then(() => {
            toast({
                title: "Credentials added",
                description: "You have successfully added SignalFx credentials",
            })
        });
    }


    return <Form {...form}>
        <form onSubmit={async (event) => {
            event.preventDefault();
            const data = Object.fromEntries(new FormData(event.currentTarget));
            try {
                const res = grafanaFormSchema.parse(data);
                await form.handleSubmit(onSubmit)()
            } catch (error) {
                console.log(error);
            }
        }}>
            <FormField
                control={form.control}
                name="grafanaUrl"
                render={({field}) => (
                    <FormItem className={"mt-4"}>
                        <FormLabel>Grafana Url</FormLabel>
                        <FormControl>
                            <Input disabled={!props.isEditable} placeholder="https://metoro.grafana.net" {...field} />
                        </FormControl>
                        <FormDescription>
                            This is the URL of your Grafana Cloud instance, Metoro will attempt to connect to this URL
                            to fetch alerts.
                        </FormDescription>
                        <FormMessage/>
                    </FormItem>
                )}
            />
            <FormField
                control={form.control}
                name="grafanaKey"
                render={({field}) => (
                    <FormItem className={"mt-4"}>
                        <FormLabel>Grafana API Key</FormLabel>
                        <FormControl>
                            <Input disabled={!props.isEditable}
                                   placeholder="glsa_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX" {...field} />
                        </FormControl>
                        <FormDescription>
                            This is the API key of your Grafana Cloud instance, Metoro will use this key to fetch
                            alerts.
                        </FormDescription>
                        <FormMessage/>
                    </FormItem>
                )}/>
            <FormField
                control={form.control}
                name="prometheusUrl"
                render={({field}) => (
                    <FormItem className={"mt-4"}>
                        <FormLabel>Prometheus Url</FormLabel>
                        <FormControl>
                            <Input disabled={!props.isEditable}
                                   placeholder="https://prometheus-prod-13-prod-us-east-0.grafana.net/api/prom" {...field} />
                        </FormControl>
                        <FormDescription>
                            This is the URL of your Prometheus Cloud instance, Metoro will attempt to connect to this
                            URL to fetch timeseries data.
                        </FormDescription>
                        <FormMessage/>
                    </FormItem>
                )}/>
            <FormField
                control={form.control}
                name="prometheusUsername"
                render={({field}) => (
                    <FormItem className={"mt-4"}>
                        <FormLabel>Prometheus Username</FormLabel>
                        <FormControl>
                            <Input disabled={!props.isEditable} placeholder="1412503" {...field} />
                        </FormControl>
                        <FormDescription>
                            This is the username of your Prometheus Cloud instance, Metoro will use this username to
                            authenticate with Prometheus.
                        </FormDescription>
                        <FormMessage/>
                    </FormItem>
                )}/>
            <FormField
                control={form.control}
                name="prometheusKey"
                render={({field}) => (
                    <FormItem className={"mt-4"}>
                        <FormLabel>Prometheus API Key</FormLabel>
                        <FormControl>
                            <Input disabled={!props.isEditable}
                                   placeholder="glc_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX" {...field} />
                        </FormControl>
                        <FormDescription>
                            This is the API key of your Prometheus Cloud instance, Metoro will use this key to fetch
                            timeseries data.
                        </FormDescription>
                        <FormMessage/>
                    </FormItem>
                )}/>
            <FormField
                control={form.control}
                name="tempoUrl"
                render={({field}) => (
                    <FormItem className={"mt-4"}>
                        <FormLabel>Tempo Url</FormLabel>
                        <FormControl>
                            <Input disabled={!props.isEditable}
                                   placeholder="https://tempo-prod-04-prod-us-east-0.grafana.net/tempo" {...field} />
                        </FormControl>
                        <FormDescription>
                            This is the URL of your Tempo Cloud instance, Metoro will attempt to connect to this URL to
                            fetch traces.
                        </FormDescription>
                        <FormMessage/>
                    </FormItem>
                )}/>
            <FormField
                control={form.control}
                name="tempoUsername"
                render={({field}) => (
                    <FormItem className={"mt-4"}>
                        <FormLabel>Tempo Username</FormLabel>
                        <FormControl>
                            <Input disabled={!props.isEditable} placeholder="802824" {...field} />
                        </FormControl>
                        <FormDescription>
                            This is the username of your Tempo Cloud instance, Metoro will use this username to
                            authenticate with Tempo.
                        </FormDescription>
                        <FormMessage/>
                    </FormItem>
                )}/>
            <FormField
                control={form.control}
                name="tempoKey"
                render={({field}) => (
                    <FormItem className={"mt-4"}>
                        <FormLabel>Tempo API Key</FormLabel>
                        <FormControl>
                            <Input disabled={!props.isEditable}
                                   placeholder="glc_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX" {...field} />
                        </FormControl>
                        <FormDescription>
                            This is the API key of your Tempo Cloud instance, Metoro will use this key to fetch traces.
                        </FormDescription>
                        <FormMessage/>
                    </FormItem>
                )}/>
            <Button disabled={!props.isEditable} className={"mt-4"} type="submit">Update credentials</Button>
        </form>
    </Form>
}

export default GrafanaCredentials;

import React, {lazy} from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Settings from "../pages/settings/settings";

const StartSearch = Loadable(lazy(() => import('pages/search/start-search')));
const Alerts = Loadable(lazy(() => import('pages/alerts/alerts')));
const Alert = Loadable(lazy(() => import('pages/alerts/alert')));
const Search = Loadable(lazy(() => import('pages/search/search')));
const MySearches = Loadable(lazy(() => import('pages/search/my-searches')));
const ChangeLog = Loadable(lazy(() => import('pages/changelog/changelog')));
const Issues = Loadable(lazy(() => import('pages/issues/issues')));
const Issue = Loadable(lazy(() => import('pages/issues/issue')));
const Billing = Loadable(lazy(() => import('pages/billing/billing')));

// pages routing
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: (
                <AuthGuard>
                    <MainLayout/>
                </AuthGuard>
            ),
            children: [
                {
                    path: 'alerts',
                    children: [
                        {
                            path: '',
                            element: <Alerts/>
                        },
                        {
                            path: ':id',
                            element: <Alert/>
                        }
                    ],
                },
                {
                    path: 'search',
                    children: [
                        {
                            path: '',
                            element: <StartSearch/>
                        },
                        {
                            path: ':id',
                            element: <Search/>
                        }
                    ],
                },
                {
                    path: 'my-searches',
                    element: <MySearches/>
                },
                {
                    path: '/',
                    element: <Alerts/>
                },
                {
                    path: 'changelog',
                    element: <ChangeLog/>
                },
                {
                    path: 'issues',
                    children: [
                        {
                            path: '',
                            element: <Issues/>
                        },
                        {
                            path: ':id',
                            element: <Issue/>
                        }
                    ]
                },
                {
                    path: 'settings',
                    element: <Settings/>
                },
                {
                    path: 'billing',
                    element: <Billing/>
                }
            ]
        },
        {
            path: '/auth',
            element: <CommonLayout/>,
            children: [
                {
                    path: 'login',
                    element: <AuthLogin/>
                },
                {
                    path: 'register',
                    element: <AuthRegister/>
                },
                {
                    path: 'forgot-password',
                    element: <AuthForgotPassword/>
                },
                {
                    path: 'reset-password',
                    element: <AuthResetPassword/>
                },
                {
                    path: 'check-mail',
                    element: <AuthCheckMail/>
                },
                {
                    path: 'code-verification',
                    element: <AuthCodeVerification/>
                }
            ]
        },
    ]
};

export default MainRoutes;

import {ReactNode, useMemo} from 'react';

// material-ui
import {CssBaseline, StyledEngineProvider} from '@mui/material';
import {createTheme, Theme, ThemeOptions, ThemeProvider, TypographyVariantsOptions} from '@mui/material/styles';

// project import
import useConfig from 'hooks/useConfig';
import Typography from './typography';
import componentsOverride from './overrides';
import {CustomShadowProps} from "../types/theme";
import CustomShadows from "./shadows";
import Palette from "./palette";

// types

// types
type ThemeCustomizationProps = {
    children: ReactNode;
};

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

export default function ThemeCustomization({children}: ThemeCustomizationProps) {
    const {themeDirection, mode, presetColor, fontFamily} = useConfig();

    const theme: Theme = useMemo<Theme>(() => Palette(mode, presetColor), [mode, presetColor]);

    const themeTypography: TypographyVariantsOptions = useMemo<TypographyVariantsOptions>(
        () => Typography(mode, fontFamily),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mode, fontFamily]
    );
    const themeCustomShadows: CustomShadowProps = useMemo<CustomShadowProps>(() => CustomShadows(theme), [theme]);

    const themeOptions: ThemeOptions = useMemo(
        () => ({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 768,
                    md: 1024,
                    lg: 1264,
                    xl: 25122
                }
            },
            direction: themeDirection,
            mixins: {
                toolbar: {
                    minHeight: 64,
                }
            },
            customShadows: themeCustomShadows,
            typography: themeTypography,
            spacing: 8
        }),
        [themeDirection,  themeTypography, themeCustomShadows]
    );

    const themes: Theme = createTheme(themeOptions);
    themes.components = componentsOverride(themes);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline/>
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

// assets
// type
// @ts-ignore
import {NavItemType} from 'types/menu';
import {IntercomContextValues} from "react-use-intercom";
import {MessageSquareIcon} from "lucide-react";

// ==============================|| MENU ITEMS - MANAGE ||============================== //

const feedback = (intercom: IntercomContextValues) => {
    return {
        id: 'group-feedback',
        title: "Feedback",
        url: '#',
        icon: MessageSquareIcon,
        onClick: () => {
            intercom.show();
        },
        type: 'group',
    }
};

export default feedback;

import {useRoutes} from 'react-router-dom';

// project import
import CommonLayout from 'layout/CommonLayout';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import {lazy} from 'react';

const Home = Loadable(lazy(() => import('pages/home')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        {
            path: '/',
            element: <CommonLayout layout="blank"/>,
            children: [
                {
                    path: '/',
                    element: (
                        <AuthGuard>
                            <MainLayout/>
                        </AuthGuard>

                    ),
                    children: [
                        {
                            path: '/',
                            element: <Home/>
                        },
                    ]
                }
            ]
        },
        LoginRoutes,
        MainRoutes
    ]);
}

import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import {Divider, List, Typography} from "@mui/material";
import {Member} from "./OrganizationDetails";
import {Avatar, AvatarFallback, AvatarImage} from "../../ui/avatar";


type MembersProp = {
    members: Member[]
    pendingMemberEmails: string[]
}

const Members = (props: MembersProp) => {
    return <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
        {props.members.map((member, index) => (
            <div>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar>
                            <AvatarImage src="https://github.com/shadcn.png" alt="@shadcn" />
                            <AvatarFallback>CN</AvatarFallback>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={member.email}
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{display: 'inline'}}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    {member.name}
                                </Typography>
                                {" — " + (member.isAdmin ? "Admin" : "Member")}
                            </React.Fragment>
                        }
                    />
                </ListItem>
                {(index === props.members.length - 1 && props.pendingMemberEmails.length === 0) ? null : <Divider variant="inset" component="li"/>}
            </div>
        ))}
        {props.pendingMemberEmails.map((email, index) => (
            <div>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar>
                            <AvatarImage src="https://github.com/shadcn.png" alt="@shadcn" />
                            <AvatarFallback>CN</AvatarFallback>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={email}
                        secondary={
                            <React.Fragment>
                                Waiting user to accept email invitation
                            </React.Fragment>
                        }
                    />
                </ListItem>
                {index === props.pendingMemberEmails.length - 1 ? null : <Divider variant="inset" component="li"/>}
            </div>
        ))}
    </List>
}

export default Members;
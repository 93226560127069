// assets

// type
import {NavItemType} from 'types/menu';
import {SettingsIcon} from "lucide-react";

// ==============================|| MENU ITEMS - MANAGE ||============================== //

const settings: NavItemType = {
    id: 'group-settings',
    title: "Settings",
    icon: SettingsIcon,
    type: 'group',
    url: '/settings',
    children: [
        {
            id: 'settings',
            title: 'Settings',
            type: 'item',
            url: '/settings',
            icon: SettingsIcon,
        },
    ]
};

export default settings;

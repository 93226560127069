import {Divider, Grid, Typography} from "@mui/material";
import React from "react";
import {Card} from "../../components/ui/card";

import OrganizationDetails, { OrganizationDetailsProps } from "../../components/settings/organization/OrganizationDetails";

export const OrganizationMembers = (props: OrganizationDetailsProps) => {
    return (
        <Card className={"p-4"}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h5"><b>Organization Members</b></Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider className={"mt-4"}/>
                </Grid>
                <Grid item xs={12}>
                    <OrganizationDetails
                        {...props}
                    />
                </Grid>
            </Grid>
        </Card>)
}

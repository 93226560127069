import {Grid} from "@mui/material";
import axios from "../../utils/axios";
import React, {useEffect, useState} from "react";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../../components/ui/tabs";
import {Integrations} from "./integrations";
import {OrganizationMembers} from "./organization-members";


// ================================|| CREDENTIAL SETUP ||================================ //

export const fetchAccountOrganizationDetails = async () => {
    return await axios.get('/api/v1/account/organization/details')
}

const Settings = () => {
    const [credsSetup, setCredsSetup] = useState(false);
    const [currCredentials, setCurrCredentials] = useState({});
    const [currCredentialVendor, setCurrCredentialVendor] = useState("");
    const [isUserAdmin, setIsUserAdmin] = useState(false);
    const [organizationName, setOrganizationName] = useState('');
    const [members, setMembers] = useState([]);
    const [pendingMemberEmails, setPendingMemberEmails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getOrganizationData = async () => {
        return fetchAccountOrganizationDetails().then(response => {
            const details = response.data
            setCredsSetup(details.credentialsSet)
            setCurrCredentials(details.backendCredential)
            setCurrCredentialVendor(details.credentialVendor)
            setIsUserAdmin(details.isAdmin)
            setOrganizationName(details.organizationName)
            setMembers(details.organizationMembers)
            setPendingMemberEmails(details.organizationPendingMemberEmails)
    })
    }

    useEffect(() => {
        getOrganizationData().then(() => {
            setIsLoading(false);
        });
    }, [])

    if (isLoading) {
        return <>Loading...</>
    }


    return (
        <Grid container className={"p-4"}>
            <Grid item xs={12}>
                <div className={"mb-4"}>
                    <h2 className="text-2xl font-bold tracking-tight">Settings</h2>
                    <p className="text-sm text-muted-foreground">
                        Manage organization settings, integrations and personal preferences.
                    </p>
                </div>
            </Grid>

            <Tabs defaultValue="integrations" className="w-full">
                <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="integrations">Integrations</TabsTrigger>
                    <TabsTrigger value="orgmembers">Organization Members</TabsTrigger>
                </TabsList>
                <TabsContent value="integrations">
                    <Integrations
                        credentialData={currCredentials}
                        isCredentialsSet={credsSetup}
                        isAdmin={isUserAdmin}
                        observabilityVendor={currCredentialVendor}
                        onSubmit={() => void 0} // No additional action needed
                    />
                </TabsContent>
                <TabsContent value="orgmembers">
                    <OrganizationMembers
                        organizationName={organizationName}
                        members={members}
                        isCurrIsUserAdmin={isUserAdmin}
                        pendingMemberEmails={pendingMemberEmails}
                        onNewMemberAdded={getOrganizationData}
                    />
                </TabsContent>
            </Tabs>
        </Grid>

    );
};

export default Settings;

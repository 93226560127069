// material-ui
import {Theme} from '@mui/material/styles';

// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableHead(theme: Theme) {
    return {
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: "#ffffff",
                    borderTop: `1px solid ${theme.palette.divider}`,
                    borderBottom: `1px solid ${theme.palette.divider}`
                }
            }
        }
    };
}

import {Button} from "../../ui/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../ui/dialog"
import {Input} from "../../ui/input"
import {toast} from "../../ui/use-toast";
import {Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage} from "../../ui/form";
import React from "react";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import axios from "../../../utils/axios";
import {z} from "zod";
import {Icons} from "../../ui/icons";


const inviteMember = async (newUserEmail: string, isAdmin: boolean) => {
    return await axios.post(
        '/api/v1/account/organization/invite',
        {
            newUserEmail: newUserEmail,
            isAdmin: isAdmin
        }
    );
}
const wait = () => new Promise((resolve) => setTimeout(resolve, 1000));


const addMemberSchema = z.object({
    emailAddress: z
        .string()
        .email({message: "Please enter a valid email address."})
        .min(3, {
            message: "email address should at least 3 characters.",
        }),
    isAdmin: z.boolean()
})

type AddMemberFormValues = z.infer<typeof addMemberSchema>

type AddMemberDialogProps = {
    refreshData: () => void
}

const AddMemberDialog = (props: AddMemberDialogProps) => {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const form = useForm<AddMemberFormValues>({
        resolver: zodResolver(addMemberSchema),

        defaultValues: {
            emailAddress: '',
            isAdmin: false
        },
        mode: "onChange",
    });

    const onSubmit = async (data: AddMemberFormValues) => {
        inviteMember(data.emailAddress, data.isAdmin)
            .then(() => {
                toast({
                    title: "Succesfully Invited",
                    description: "The new member will receive an email with an invitation link to join the organization.",
                });
            }).catch((error) => {
            toast({
                title: "Error",
                description: "Could not invite the new member, please contact support."
            });
        })
    }

    return <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
            <Button variant="outline">Invite New Member</Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
                <DialogTitle>Invite New Member</DialogTitle>
                <DialogDescription>
                    Collaborate with your team by inviting new members to join your organization.
                </DialogDescription>
            </DialogHeader>
            <Form {...form}>
                <form onSubmit={async (event) => {
                    setLoading(true);
                    form.handleSubmit(onSubmit)().then(
                        () => {
                            wait().then(() => {
                                setOpen(false);
                                try {
                                    props.refreshData();
                                } catch (e) {
                                    console.error(e);
                                }
                                setLoading(false);
                            });
                            event.preventDefault();
                        }
                    )
                }}>
                    <FormField
                        control={form.control}
                        name="emailAddress"
                        render={({field}) => (
                            <FormItem>
                                <FormLabel>Email Address</FormLabel>
                                <FormControl>
                                    <Input placeholder="e.g. jane.smith@company.com" {...field} />
                                </FormControl>
                                <FormMessage/>
                                <FormDescription>
                                    Please enter the email address of the new member. They will receive an invitation
                                    link to join Metoro.
                                </FormDescription>
                            </FormItem>
                        )}
                    />
                    <DialogFooter>
                        <Button disabled={loading} type="submit">{
                            loading ?
                                <Icons.Spinner className="mr-2 h-4 w-4 mx-auto animate-spin"/>
                                : "Invite"
                        }</Button>
                    </DialogFooter>
                </form>
            </Form>
        </DialogContent>
    </Dialog>
}

export default AddMemberDialog